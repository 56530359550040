<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable && $can('create_stc')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3 mr-3" @click="modalAdd.active = true">Tambah</vs-button>
            <vs-button v-if="!selectable" color="primary" :type="isEmptyFilter ? 'flat' : 'filled'" icon-pack="feather" icon="icon-filter" class="px-3" @click="modalFilter.active = true">Filter</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_stc">No. STC</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_stc">Tgl. STC</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_kontrak">No. Kontrak</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_ajb">No. AJB</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_ajb">Tgl. AJB</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_approval">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_approval">Approval Progress</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_garansi">No. Garansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="masa_garansi">Masa Garansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_habis_garansi">Tgl. Habis Garansi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_habis_garansi">Nama Penerima</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_habis_garansi">Notaris</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="file">Files</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="gambar">Gambar</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="username">Created By</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer" v-if="$canAny(['update_stc', 'delete_stc'])">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-if="item.status_approval === 'DITERIMA'" class="p-1" @click="printStc(item.id)">Print</vs-dropdown-item>
                  <vs-dropdown-item class="p-1" v-if="$can('update_stc')" @click="showModalEdit(item)" :disabled="item.status_approval === 'DITERIMA'"><span class="whitespace-no-wrap" >Edit</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" v-if="$can('delete_stc')" @click="confirmDelete(item.id)" :disabled="item.status_approval === 'DITERIMA'"><span class="whitespace-no-wrap text-danger" >Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_stc }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_stc }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_kontrak }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_ajb }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_ajb }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span v-if="item.status_approval" class="text-xs underline cursor-pointer" @click="showModalApproval(item)">Lihat</span>
              <span v-else class="text-sm">-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_garansi }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.masa_garansi }} hari</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_habis_garansi }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_penerima }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.notaris }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <span v-if="item.files_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
              <span v-else class="text-sm">-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <img v-if="item.foto" :src="item.foto_url[0]" @click="showImages(item.foto_url)" class="w-8 h-auto cursor-pointer" alt="photos"/>
              <span v-else>-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.username }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <SerahTerimaCustomerFilter :isActive.sync="modalFilter.active" @filter="onFilter"/>
    <SerahTerimaCustomerAdd v-if="!selectable" :isActive.sync="modalAdd.active" @success="getData"/>
    <SerahTerimaCustomerEdit v-if="!selectable" :isActive.sync="modalEdit.active" @success="getData" :item="modalEdit.item"/>
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import SerahTerimaCustomerRepository from '@/repositories/marketing/serah-terima-customer-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import _ from 'lodash'

export default {
  name: 'TabSerahTerimaCustomerD',
  props: {
    selectable: { default: false, type: Boolean }
  },
  components: {
    SerahTerimaCustomerFilter: () => import('@/views/pages/marketing/serah-terima-customer/SerahTerimaCustomerFilter'),
    SerahTerimaCustomerAdd: () => import('@/views/pages/marketing/serah-terima-customer/SerahTerimaCustomerAdd'),
    SerahTerimaCustomerEdit: () => import('@/views/pages/marketing/serah-terima-customer/SerahTerimaCustomerEdit'),
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress'),
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    ImageViewer,
    PagingLimit
  },
  mixins: [printoutMixin],
  data () {
    return {
      isLoading: false,
      errors: null,
      filter: {},
      modalFilter: {
        active: false
      },
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalFiles: {
        filesUrl: [],
        active: false
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })

      SerahTerimaCustomerRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      SerahTerimaCustomerRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    onFilter (filterData) {
      this.filter = filterData
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'STC'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    printStc (idStc) {
      this.showPrintOutFile('BAST_STC', { id: idStc })
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
